@import "~@/assets/base/styles.less";

.home-page {
  width: 100%;
  margin-top: @HEAD_HEIGHT;
  display: flex;
  flex-direction: column;
  align-items: center;

  .session-1 {
    width: 100%;
    background-color: #FAFBFC;
    display: flex;
    flex-direction: column;
    align-items: center;

    .session-1-div {
      width: 100%;
      max-width: @MAX_WIDTH;
      padding: 90px 165px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      @media screen and (max-width: @MOBILE_WIDTH) {
        flex-direction: column-reverse;
        padding: 50px 16px;
      }

      .text-div {
        width: 50%;
        @media screen and (max-width: @MOBILE_WIDTH) {
          width: 100%;
        }

        .session-1-title {
          font-size: 40px;
          font-weight: 700;
          color: black;
          @media screen and (max-width: @MOBILE_WIDTH) {
            font-size: 25px;
            margin-top: 30px;
          }
        }
        .session-1-content {
          margin-right: 50px;
          font-size: 16px;
          color: rgba(black, 0.6);
          line-height: 1.5;
          margin-top: 24px;
          @media screen and (max-width: @MOBILE_WIDTH) {
            margin-right: 0;
            font-size: 14px;
          }
        }
        .contact-button {
          width: 148px;
          height: 48px;
          border-radius: 4px;
          background-color: black;
          font-size: 16px;
          color: white;
          font-weight: 500;
          margin-top: 64px;
          @media screen and (max-width: @MOBILE_WIDTH) {
            margin-top: 30px;
            font-size: 15px;
          }
        }
      }
      .image {
        width: 50%;
        @media screen and (max-width: @MOBILE_WIDTH) {
          width: 100%;
        }
      }
    }
  }
  .session-2 {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    .session-2-div {
      width: 100%;
      max-width: @MAX_WIDTH;
      padding: 80px 165px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: @MOBILE_WIDTH) {
        padding: 40px 16px;
      }

      .session-2-title {
        font-size: 28px;
        font-weight: 700;
        color: black;
        @media screen and (max-width: @MOBILE_WIDTH) {
          font-size: 18px;
        }
      }
      .session-2-content {
        margin-top: 16px;
        font-size: 16px;
        color: rgba(black, 0.6);
        @media screen and (max-width: @MOBILE_WIDTH) {
          font-size: 14px;
        }
      }
      .session-2-list {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin: 40px 0;
        justify-content: space-between;
        @media screen and (max-width: @MOBILE_WIDTH) {
          flex-wrap: wrap;
        }

        .session-2-li {
          width: calc(20% - 8px);
          height: 0;
          padding-bottom: calc(20% - 8px);
          background-color: #F9FBFC;
          border-radius: 8px;
          position: relative;
          @media screen and (max-width: @MOBILE_WIDTH) {
            width: calc(50% - 5px);
            padding-bottom: calc(50% - 5px);
            margin: 5px 0;
          }
        }
        .session-2-li-div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            height: 64px;
            @media screen and (max-width: @MOBILE_WIDTH) {
              height: 50px;
            }
          }
          p {
            font-size: 16px;
            color: black;
            font-weight: 500;
            line-height: 1.5;
            margin-top: 18px;
            @media screen and (max-width: @MOBILE_WIDTH) {
              font-size: 14px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .session-3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .session-3-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 460px;
      background-color: black;
      z-index: -1;
      @media screen and (max-width: @MOBILE_WIDTH) {
        height: 250px;
      }
    }
    .session-3-div {
      width: 100%;
      max-width: @MAX_WIDTH;
      padding: 80px 165px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: @MOBILE_WIDTH) {
        padding: 40px 16px;
      }

      .session-3-title {
        font-size: 28px;
        font-weight: 700;
        color: white;
        @media screen and (max-width: @MOBILE_WIDTH) {
          font-size: 18px;
        }
      }
      .session-3-content {
        margin-top: 16px;
        font-size: 16px;
        color: rgba(white, 0.7);
        @media screen and (max-width: @MOBILE_WIDTH) {
          font-size: 14px;
        }
      }
      .session-3-tab-div {
        width: 100%;
        margin-top: 40px;
        border-radius: 16px;
        background-color: white;
        box-shadow: 0px 4px 40px rgba(black, 0.08);

        .ant-tabs-nav {
          margin-bottom: 0;
        }
        .ant-tabs-nav-list {
          width: 100%;
          height: 74px;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          @media screen and (max-width: @MOBILE_WIDTH) {
            height: 60px;
          }

          .ant-tabs-tab {
            font-size: 20px;
            color: black;
            font-weight: 500;
            @media screen and (max-width: @MOBILE_WIDTH) {
              font-size: 15px;
            }
          }
          .ant-tabs-tab-active .ant-tabs-tab-btn {
            color: black;
            text-shadow: none;
          }
          .ant-tabs-ink-bar {
            background-color: black;
          }
        }
        .session-3-tab-content {
          width: 100%;
          // margin-top: -16px;
        }
      }
    }
  }
  .session-4 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: @MAX_WIDTH;
    padding: 80px 165px;
    box-sizing: border-box;
    @media screen and (max-width: @MOBILE_WIDTH) {
      padding: 40px 16px;
    }

    .session-4-title {
      font-size: 28px;
      font-weight: 700;
      color: black;
      @media screen and (max-width: @MOBILE_WIDTH) {
        font-size: 18px;
      }
    }
    .session-4-list {
      width: 100%;
      margin: 40px 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      @media screen and (max-width: @MOBILE_WIDTH) {
        justify-content: space-between;
      }

      .session-4-li {
        width: calc(33.3%);
        padding: 40px 64px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (max-width: @MOBILE_WIDTH) {
          width: 50%;
          padding: 20px;
        }

        .session-4-image-div {
          width: 104px;
          height: 104px;
          border-radius: 104px;
          background-color: #F4F8FC;
          display: flex;
          align-items: center;
          justify-content: center;
          @media screen and (max-width: @MOBILE_WIDTH) {
            width: 80px;
            height: 80px;
          }

          img {
            width: 48px;
            @media screen and (max-width: @MOBILE_WIDTH) {
              width: 30px;
            }
          }
        }
        .session-4-li-title {
          margin-top: 32px;
          font-size: 20px;
          color: black;
          font-weight: 500;
          @media screen and (max-width: @MOBILE_WIDTH) {
            margin-top: 20px;
            font-size: 16px;
          }
        }
        .session-4-li-content {
          font-size: 14px;
          margin-top: 16px;
          color: rgba(black, 0.6);
          line-height: 1.5;
          text-align: center;
          @media screen and (max-width: @MOBILE_WIDTH) {
            margin-top: 10px;
            font-size: 12px;
          }
        }
      }
    }
  }
  .session-5 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: @MAX_WIDTH;
    padding: 80px 165px;
    box-sizing: border-box;
    @media screen and (max-width: @MOBILE_WIDTH) {
      padding: 40px 16px;
    }
  }
  .session-5-title {
    font-size: 28px;
    font-weight: 700;
    color: black;
    @media screen and (max-width: @MOBILE_WIDTH) {
      font-size: 18px;
    }
  }
  .session-5-list {
    margin: 60px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: @MOBILE_WIDTH) {
      margin: 30px 0;
      flex-wrap: wrap;
    }

    img {
      width: calc(20% - 8px);
      @media screen and (max-width: @MOBILE_WIDTH) {
        width: calc(50% - 8px);
        margin: 10px 0;
      }
    }
  }
}
.contact-modal .ant-modal {
  width: auto !important;
}
.contact-modal .ant-modal-content {
  background-color: transparent;
  border-radius: 8px;
  width: 100%;
  box-shadow: none;
}
.contact-modal .ant-modal-content .ant-modal-body {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.contact-modal .ant-modal-content .ant-modal-body img {
  width: 100%;
}