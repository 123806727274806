@import "~@/assets/base/styles.less";

.not-found-page {
  width: 100%;
  margin-top: @HEAD_HEIGHT;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - @HEAD_HEIGHT);
  padding: 0 165px;
  box-sizing: border-box;

  p {
    color: #131926;
    font-size: 32px;
    text-align: center;
  }
} 