@import "~@/assets/base/styles.less";

.foot-component {
  width: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;

  .foot-body {
    width: 100%;
    max-width: @MAX_WIDTH;
    padding: 48px 165px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.5;
    color: white;
    @media screen and (max-width: @MOBILE_WIDTH) {
      padding: 30px 16px;
      font-size: 12px;
    }

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}