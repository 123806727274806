@import "~@/assets/base/styles.less";

.head-component {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  background-color: #FAFBFC;
  display: flex;
  align-items: center;
  justify-content: center;

  .head-body {
    width: 100%;
    max-width: @MAX_WIDTH;
    height: @HEAD_HEIGHT;
    padding: 0 165px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #FAFBFC;
    @media screen and (max-width: @MOBILE_WIDTH) {
      padding: 0 16px;
    }
    
    .logo {
      height: 40px;
      @media screen and (max-width: @MOBILE_WIDTH) {
        height: 30px;
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;

      p {
        font-size: 16px;
        color: black;
        margin-left: 20px;
        cursor: pointer;
        @media screen and (max-width: @MOBILE_WIDTH) {
          font-size: 14px;
        }
      }
    }
  }
}